import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { type CtrlChainIcons } from '@cca-common/core';

@Component({
  imports: [MatIconModule],
  selector: 'cca-icon',
  template: `
    <mat-icon
      [inline]="inline()"
      fontSet="cca-icon"
      [fontIcon]="'cca-icon-' + icon()"
    />
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
  readonly icon = input.required<CtrlChainIcons>();
  readonly inline = input(true);
}
